import { MDBBtn } from 'mdb-react-ui-kit'

import {Link} from 'react-router-dom'

import classes from './AllVendors.module.css'

function AllVendors() {
  return (
    <div className='row justify-content-center'>
      <div className='col-12 col-lg-8'>
      {/* <Link to='/anbieter/kredupay'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          KREDUPAY
        </MDBBtn>
        </Link> */}
        <Link to='/anbieter/paysafecard'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          paysafecard
        </MDBBtn>
        </Link>
        <Link to='/anbieter/google-play'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          Google Play
        </MDBBtn>
        </Link>
        <Link to='/anbieter/sony-playstation'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          Sony Playstation
        </MDBBtn>
        </Link>
        <Link to='/anbieter/xbox'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          XBOX
        </MDBBtn>
        </Link>
        <Link to='/anbieter/steam'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          STEAM
        </MDBBtn>
        </Link>
        <Link to='/anbieter/amazon'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          Amazon
        </MDBBtn>
        </Link>
        <Link to='/anbieter/bigpoint'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          BIGPOINT
        </MDBBtn>
        </Link>
        <Link to='/anbieter/bildmobil'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          Bildmobil
        </MDBBtn>
        </Link>
        <Link to='/anbieter/blau'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          Blau
        </MDBBtn>
        </Link>
        <Link to='/anbieter/congstar'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          congstar
        </MDBBtn>
        </Link>
        <Link to='/anbieter/deezer'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          DEEZER
        </MDBBtn>
        </Link>
        <Link to='/anbieter/e-plus'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          e-plus
        </MDBBtn>
        </Link>
        <Link to='/anbieter/fonic'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          fonic
        </MDBBtn>
        </Link>        
        <Link to='/anbieter/klarmobil'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          Klarmobil
        </MDBBtn>
        </Link>        
        <Link to='/anbieter/lebara'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          Lebara
        </MDBBtn>
        </Link>
        <Link to='/anbieter/lycamobile'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          Lycamobile
        </MDBBtn>
        </Link>
        <Link to='/anbieter/mobi'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          Mobi
        </MDBBtn>
        </Link>
        <Link to='/anbieter/netflix'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          NETFLIX
        </MDBBtn>
        </Link>
        <Link to='/anbieter/nintendo'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          Nintendo
        </MDBBtn>
        </Link>
        <Link to='/anbieter/o2'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          O2
        </MDBBtn>
        </Link>
        <Link to='/anbieter/ortel'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          ortel
        </MDBBtn>
        </Link>
        <Link to='/anbieter/otelo'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          otelo
        </MDBBtn>
        </Link>         
        <Link to='/anbieter/sony-ps-plus'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          Sony Playstation plus
        </MDBBtn>
        </Link>
        <Link to='/anbieter/spotify'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          Spotify
        </MDBBtn>
        </Link>        
        <Link to='/anbieter/telekom'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          Telekom
        </MDBBtn>
        </Link>
        <Link to='/anbieter/turkcell'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          Turkcell
        </MDBBtn>
        </Link>
        <Link to='/anbieter/vodafone'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          vodafone
        </MDBBtn>
        </Link>        
        <Link to='/anbieter/yourfone'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          yourfone
        </MDBBtn>
        </Link>
        <Link to='/anbieter/zalando'>
        <MDBBtn className={`${classes.btnVoucher} text-capitalize me-2 mb-2`}>
          Zalando
        </MDBBtn>
        </Link>
      </div>
    </div>
  )
}

export default AllVendors
