import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import cartService from '../services/cartService'
import { revertCartSubmit } from '../redux/cartSlice'
import { MDBCard, MDBBtn, MDBRadio, MDBSpinner } from 'mdb-react-ui-kit'
import CartItem from './CartItem'
import CartItemOutOfStock from './CartItemOutOfStock'
import CartError from './CartError'
import {
  reset,
  setPaymentMethodInCart,
  loadProductDataOrderLimitDiscountAndOpenOrdersAmount,
  submitCart,
} from '../redux/cartSlice'
import LoadingSpinner from '../../../components/LoadingSpinner'
import FormError from '../../../components/FormError'
import FriendlyInfoBox from '../../../components/FriendlyInfoBox'

import klarna from '../assets/img/Klarna.svg'
import kredu from '../assets/img/Kredu-logo.svg'

import classes from '../assets/css/Cart.module.css'
import { ROUTES } from '../../../data/constants'
import { signOut } from '../../auth/authSlice'
import { sub } from 'date-fns'

function Cart() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    isLoading,
    isSubmitLoading,
    isCartSubmitted,
    isError,
    message,
    isSubmitError,
    cartItems,
    serviceFee,
    serviceFeeVat,
    totalAmount,
    totalAmountWithoutServiceFee,
    prepaidOrderLimit,
    bnplOrderLimit,
    paymentMethod,
    kredupayDiscount,
    kredupayDiscountAmount,
    hasOpenPaysLegacyInvoices,
    hasOpenKredupayInvoices,
    hasOverdueInvoices,
    paysafecardLimit,
    paysafecardAvailableLimit,
    isPaysafecardLimitExceeded,
  } = useSelector((state) => state.cart)

  const { user } = useSelector((state) => state.auth)

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState(paymentMethod)

  useEffect(() => {
    dispatch(reset())
    dispatch(loadProductDataOrderLimitDiscountAndOpenOrdersAmount()).then(
      () => {
        if (user && user.token) {
          dispatch(revertCartSubmit())
          if (message === '401') {
            dispatch(signOut())
          } else {
            cartService.revertCartSubmit(user.token)
          }
        }
      }
    )
  }, [])

  useEffect(() => {
    if (isCartSubmitted) {
      if (!isSubmitError) {
        if (user) {
          navigate(ROUTES.CHECKOUT)
        } else {
          navigate(ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_EMAIL)
        }
      }
    }
  }, [isCartSubmitted])

  const isPaymentMethodRadioChecked = (value) => {
    return selectedPaymentMethod === value
  }

  const shouldPrepaidPaymentBeDisabled = () => {
    return true
    //If hour between 0 and 7 return true
    const hour = new Date().getHours()
    return hour >= 0 && hour < 7
  }

  const handlePaymentMethodChange = (e) => {
    setSelectedPaymentMethod(e.currentTarget.value)
    dispatch(setPaymentMethodInCart(e.currentTarget.value))
  }

  const handleCartSubmit = async (e) => {
    dispatch(submitCart())
  }

  const hasOpenInvoices =
    selectedPaymentMethod === 'BNPL' &&
    (hasOpenPaysLegacyInvoices || hasOpenKredupayInvoices || hasOverdueInvoices)

  if (isLoading) {
    return (
      <div
        className={`${classes.container} vh-100 pb-5 pt-5 pt-lg-3 mt-5 container`}
      >
        <div className={`mx-auto ${classes.spinnerContainer}`}>
          <LoadingSpinner />
        </div>
      </div>
    )
  }

  if (isError) {
    return (
      <div
        className={`${classes.container} pb-5 text-center pt-5 pt-lg-3 mt-5 container`}
      >
        <CartError />
      </div>
    )
  }

  return (
    <div className='container h-100 pb-5 pt-4 pt-lg-3 mt-5'>
      <h2 className='mb-lg-4 pt-3 pb-2 pt-lg-5 header-top fw-bold'>
        Dein Warenkorb
      </h2>
      {totalAmount <= 0 && cartItems.every((c) => !c.isInStock) && (
        <div className={classes.infoBox}>
          <FriendlyInfoBox>
            <div>
              Dein Warenkorb enthält nur Artikel, die derzeit nicht verfügbar
              sind. Gehe zurück zu unserer Produktliste, um verfügbare Artikel
              in den Warenkorb zu legen. <br />{' '}
              <Link
                to='/alle-produkte'
                className={`${classes.fontBlue}  fw-bold text-decoration-underline`}
              >
                {' '}
                Zu allen Produkten
              </Link>
            </div>
          </FriendlyInfoBox>
        </div>
      )}
      <div className='row d-none d-lg-flex'>
        <div className='col-12 col-lg-5 col-xl-3'>
          <p className='ps-2 text-muted'>Produkt</p>
        </div>
        <div className='col-2 d-none text-center d-xl-block'>
          <p className='ps-2 text-muted'>Preis</p>
        </div>
        <div className='col-12 col-lg-3'>
          <p className='text-center text-muted'>Menge</p>
        </div>
        <div className='col-3'>
          <p className='text-center text-muted'>Gesamtpreis</p>
        </div>
        <div className='col-1'></div>
      </div>

      {cartItems.map((cartItem) => {
        return cartItem.isInStock === true ? (
          <CartItem
            key={cartItem.productId}
            productId={cartItem.productId}
            productName={cartItem.name}
            quantity={cartItem.quantity}
            price={cartItem.price}
            slug={cartItem.slug}
          />
        ) : (
          <CartItemOutOfStock
            key={cartItem.productId}
            productId={cartItem.productId}
            productName={cartItem.name}
            slug={cartItem.slug}
          />
        )
      })}

      <MDBCard className={`${classes.shoppingCart} mb-3 p-md-3 p-2`}>
        <div className='row'>
          <div className='col-8'>
            <p className='fw-bold mb-0 mb-lg-1'>Servicegebühr</p>
          </div>
          <div className='col-3 d-flex align-items-center justify-content-md-center justify-content-end pe-lg-0'>
            <p className='fw-bold pe-2 pe-md-0 ps-md-3 mb-1'>
              <span className={classes.small}>
                {(kredupayDiscountAmount
                  ? serviceFee + kredupayDiscountAmount
                  : serviceFee
                )
                  .toFixed(2)
                  .replace('.', ',')}
              </span>
              &nbsp;€
            </p>
          </div>
          <div>
            <p className='col text-muted mb-1 d-block d-md-none'>
              Servicegebühr für die Bearbeitung des Verkaufs von Gutscheinen
            </p>
          </div>
        </div>
      </MDBCard>
      {kredupayDiscount > 0 && (
        <MDBCard className='shoppingCart mb-3 p-md-3 p-2'>
          <div className='row'>
            <div className='col-8'>
              <p className='fw-bold mb-1 mb-lg-1'>Kundenrabatt</p>
              <p className='text-muted d-none mb-0 d-md-block'>
                Rabatt durch aktive Jahreskartengebühr im Kredupay
              </p>
            </div>
            <div className='col-3 d-flex align-items-center justify-content-md-center justify-content-end pe-lg-0'>
              <p className='fw-bold pe-2 pe-md-0 ps-md-3 mb-1'>
                <span className={classes.small}>
                  -
                  {kredupayDiscountAmount
                    ? kredupayDiscountAmount.toFixed(2).replace('.', ',')
                    : 0}
                </span>
                €
              </p>
            </div>
            <div className='col'>
              <p className='text-muted d-block mb-1 d-md-none'>
                Rabatt durch aktive Jahreskartengebühr im Kredupay
              </p>
            </div>
          </div>
        </MDBCard>
      )}
      <div className='row shopping-cart-total-loggedIn'>
        <div className='col-12 d-flex justify-content-end'>
          <div className='d-flex mb-0'>
            <p className='me-3 mb-0 mt-1'>Gesamtsumme</p>
            <h3 className='d-inline-block me-1'>
              <span className='fw-bold'>
                {totalAmount.toFixed(2).replace('.', ',')}
              </span>
            </h3>
            €
          </div>
        </div>
        <p className={`${classes.vatBox} mt-0 mb-2 text-end`}>
          inkl. 19 % MwSt.{' '}
          <span className='ms-2'>
            {serviceFeeVat.toFixed(2).replace('.', ',')}&nbsp;
          </span>
          €{' '}
        </p>
        <div className='col-12 d-flex justify-content-end  align-items-center'>
          <div className={classes.paymentsBox}>
            <p className='fw-bold text-end'>Wie möchtest du zahlen?</p>
            <div
              className={`${classes.checkBoxes} ${classes.boxBorder} bg-white pt-3 pb-2 px-3 mb-0`}
            >
              <div className={`d-flex`}>
                <MDBRadio
                  name='paymentMethod'
                  id='radio1'
                  value='SOFORT'
                  label='Sofortüberweisung'
                  checked={
                    isPaymentMethodRadioChecked('SOFORT') &&
                    !shouldPrepaidPaymentBeDisabled()
                  }
                  onChange={handlePaymentMethodChange}
                  disabled={shouldPrepaidPaymentBeDisabled()}
                />
                <div className={`${classes.klarna} ms-3`}>
                  <img src={klarna} width='40px' alt='klarna'></img>
                </div>
              </div>
              <p className='text-muted ms-4 my-2'>
                {' '}
                Sicher und direkt mit Klarna bezahlen. Lieferung erfolgt erst
                nach Zahlung.
                <br /> Normale Überweisung auch möglich.
              </p>
              {/* {shouldPrepaidPaymentBeDisabled() && (
                <div
                  className={`px-4 py-3 mb-2 text-muted ${classes.borderText}`}
                >
                  Du kannst zwischen 00:00 und 7:00 Uhr keine Sofortüberweisung
                  tätigen. <br />
                  Bitte wähle eine andere Zahlungsmethode.
                </div>
              )} */}
              {selectedPaymentMethod === 'SOFORT' &&
                totalAmountWithoutServiceFee > prepaidOrderLimit && (
                  <div
                    className={`px-4 py-3 mb-2 text-muted ${classes.borderText}`}
                  >
                    Du hast dein maximales Limit für diese Zahlungsmethode
                    erreicht.
                    <br />
                    Dein Limit beträgt derzeit{' '}
                    <b>{prepaidOrderLimit.toFixed(2).replace('.', ',')} € </b>
                    und wird nach mehreren erfolgreichen Bestellungen erhöht.{' '}
                    {prepaidOrderLimit === 0.0
                      ? ''
                      : 'Reduziere die Artikel in deinem Warenkorb, um Sofort als Zahlungsmethode zu verwenden.'}
                  </div>
                )}
            </div>
            <div
              className={`${classes.checkBoxes} ${classes.boxDown} bg-white pt-3 pb-2 px-3 mb-4`}
            >
              <div className='d-flex'>
                <MDBRadio
                  name='paymentMethod'
                  id='radio2'
                  value='BNPL'
                  label='In 30 Tage bezahlen'
                  checked={
                    isPaymentMethodRadioChecked('BNPL') ||
                    shouldPrepaidPaymentBeDisabled()
                  }
                  onChange={handlePaymentMethodChange}
                />
                <div className={`ms-3`}>
                  <img src={kredu} width='40px' alt='kredu'></img>
                </div>
              </div>
              <p className='text-muted ms-4 my-2'>
                Du erhältst nach der Bestellung eine Rechnung und die Gutscheine
                per E-Mail. <br />{' '}
                <span className={classes.smallInfo}>Bonität vorausgesetzt</span>
              </p>
              {selectedPaymentMethod === 'BNPL' &&
                totalAmountWithoutServiceFee > bnplOrderLimit && (
                  <div
                    className={`px-4 py-3 mb-2 text-muted ${classes.borderText}`}
                  >
                    Du hast dein maximales Limit für diese Zahlungsmethode
                    erreicht.
                    <br />
                    Dein Limit beträgt derzeit{' '}
                    <b>{bnplOrderLimit.toFixed(2).replace('.', ',')} € </b>
                    und wird nach mehreren erfolgreichen Bestellungen erhöht.{' '}
                    {bnplOrderLimit === 0.0
                      ? ''
                      : 'Reduziere die Artikel in deinem Warenkorb, um Kauf auf Rechnung als Zahlungsmethode zu verwenden.'}
                  </div>
                )}
            </div>
            {isSubmitError && <FormError />}
            {(hasOpenInvoices || hasOpenPaysLegacyInvoices) && (
              <FormError
                errors={[
                  'Du kannst aufgrund unbezahlter Rechnungen keine weiteren Einkäufe tätigen. Bitte bezahle die fälligen Rechnungen, um wieder einkaufen zu können.',
                ]}
              />
            )}
            {(isPaysafecardLimitExceeded && !hasOpenInvoices && !hasOpenPaysLegacyInvoices) && (
              <FormError
                errors={[
                  `Aufgrund der hohen Nachfrage ist es vorübergehend möglich, Paysafecards im Wert von maximal ${paysafecardLimit.toFixed(0).replace('.', ',')}€ auf Rechnung zu bestellen. Im Moment hast du 
                    noch ${paysafecardAvailableLimit.toFixed(0).replace('.', ',')}€ deines Limits übrig. Bitte reduziere die Anzahl der Paysafecards 
                    in deinem Warenkorb oder bezahle offene Rechnungen zurück, um mehr bestellen zu können.`,
                ]}
              />
            )}
            <MDBBtn
              className={`btn-yellow btn w-100 mt-2 ${
                totalAmount === 0 ||
                (selectedPaymentMethod === 'SOFORT' &&
                  totalAmountWithoutServiceFee > prepaidOrderLimit) ||
                (selectedPaymentMethod === 'BNPL' &&
                  totalAmountWithoutServiceFee > bnplOrderLimit) ||
                hasOpenInvoices || isPaysafecardLimitExceeded
                  ? 'disabled'
                  : ''
              }`}
              onClick={handleCartSubmit}
            >
              {isSubmitLoading && (
                <MDBSpinner
                  size='sm'
                  role='status'
                  tag='span'
                  className={`me-2`}
                />
              )}
              Weiter
            </MDBBtn>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cart
